@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/* Edit the scrollbar to fit theme */
::-webkit-scrollbar {
  width: 10px;
}

/* Track style */
::-webkit-scrollbar-track {
  background: #e0e0e0
}

/* Handle */
::-webkit-scrollbar-track:hover {
  background: #555;
}